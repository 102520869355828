const baseUrl = process.env.VUE_APP_BASE_URL;
const api = '/api';
const user = '/user';
const branch = '/branch';
const dashboard = '/dashboard';
const compartments = '/compartments';
const crops = '/crops';
const parentals = '/parentals';
const varieties = '/variety';
const variety = '/variety';
const login = '/login';
const enable = '/enable';
const list = '/list';
const productionLines = '/production-lines';
const orders = '/orders';
const occupancy = '/occupancy';
const orderFiles = '/orders';
const production = '/production';
const users = '/user';

module.exports = {

    loginUrl: () => `${baseUrl}${api}${login}`,
    branchUrl: id => `${baseUrl}${api}${branch}${id ? '/' + id : ''}`,
    branchListUrl: () => `${baseUrl}/records/crops/get`,
    branchEnableUrl: () => `${baseUrl}${api}${branch}${enable}`,

    dashboardProductionByStaged: () => `${baseUrl}${dashboard}/productionbystage`,
    dashboardGetSurfaceByCrop: () => `${baseUrl}${dashboard}/surfacebycrop`,
    dashboardGetCropByCompartment: () =>`${baseUrl}${dashboard}/cropbycompartment`,

    userListUrl: id => `${baseUrl}${users}/get`,
    userGetRoles: () => `${baseUrl}${users}/roles/list/select`,
    saveUserUrl: id => `${baseUrl}${users}${id ? '/save' : '/save'}`,
    userDeleteUrl: () => `${baseUrl}${users}/delete`,
    userChangeStatus: () => `${baseUrl}${users}/changeStatus`,

    compartmentUrl: () => `${baseUrl}${compartments}`,
    compartmentSaveUrl: id => `${baseUrl}${compartments}/save`,
    compartmentDeleteUrl: () => `${baseUrl}${compartments}/delete`,
    compartmentListUrl: () => `${baseUrl}${compartments}/get`,

    //compartmentOccupancyListUrl: id => `${baseUrl}${compartments}/info/get${id ? '?id=' + id : '' }`,
    compartmentOccupancyListUrl: id => `${baseUrl}${compartments}/show${id ? '?id=' + id : '' }`,
    //original productionLinesOccupancyListUrl: () =>`${baseUrl}${occupancy}/compartment/get`,
    productionLinesOccupancyListUrl: () =>`${baseUrl}/compartments/occupancy`,
    compartmentWithOccupancyUrl: id => `${baseUrl}${occupancy}/get${id ? '/' + id : ''}`,
    compartmentProductionLines: () => `${baseUrl}${productionLines}/list-unassigned-production-lines`,
    compartmentOccupancyAssign: () => `${baseUrl}${occupancy}/compartment/create`,
    unassignedProductionLine: () => `${baseUrl}${productionLines}/unassign`,

    cropUrl: () => `${baseUrl}${crops}`,
    cropSaveUrl: id => `${baseUrl}${crops}${id ? '/save' : '/save'}`,
    cropDeleteUrl: () => `${baseUrl}${crops}/delete`,
    cropListUrl: ()=> `${baseUrl}${crops}/get`,
    cropFamilySelectUrl: () => `${baseUrl}${crops}/crop-family/list/select`,

    parentsUrl: () => `${baseUrl}${parentals}`,
    parentSaveUrl: id => `${baseUrl}${parentals}${id ? '/save' : '/save'}`,
    parentDeleteUrl: () => `${baseUrl}${parentals}/delete`,
    parentListUrl: () => `${baseUrl}${parentals}/get`,
    parentFamilySelectUrl: () => `${baseUrl}${crops}/crop-family/list/select`,

    varietyUrl: () => `${baseUrl}${varieties}`,
    varietySaveUrl: id => `${baseUrl}${variety}${id ? '/save' : '/save'}`,
    varietyDeleteUrl: () => `${baseUrl}${variety}/delete`,
    varietyListUrl: () => `${baseUrl}${varieties}/get`,
    varietyCropFamilySelectUrl: id => `${baseUrl}${crops}/crop-family/list/select`,
    varietyCropSelectUrl: () => `${baseUrl}${crops}/by-family/list/select`,
    varietyFemaleSelectUrl: () => `${baseUrl}${parentals}/list/select`,
    varietyMaleSelectUrl: () => `${baseUrl}${parentals}/list/select`,
    varietyPollinationTypeSelectUrl: () => `${baseUrl}/pollination-type/list/select`,
    varietyChangeStatus: () => `${baseUrl}/variety/inbred-risk`,
    varietyAddImage: () => `${baseUrl}/variety/save-graphic`,
    varietyHarvestMethodUrl: () => `${baseUrl}/harvest-method/list/select`,
    //varietiesSelectUrl: () => `${baseUrl}/varieties/get-list`,
    varietiesSelectUrl: () => `${baseUrl}/variety/list/select`,
    getVarietyByIdUrl: id => `${baseUrl}${varieties}${id ? '/' + id : ''}`,

    productionLineListUrl: () => `${baseUrl}/production-lines/unassigned`,
    getProductionLineByIdUrl: id => `${baseUrl}${productionLines}${id ? '/' + id : ''}`,
    productionLineSaveUrl: () => `${baseUrl}${productionLines}/save`,
    productionLineDivideUrl: () => `${baseUrl}${productionLines}/divide`,
    productionLineDeleteUrl: () => `${baseUrl}${productionLines}/prod-delete`,
    productionLineCancelUrl: id => `${baseUrl}${productionLines}/cancel${id ? '/' + id : ''}`,
    productionLineReleaseCompartmentUrl: id => `${baseUrl}${productionLines}/release-compartment${id ? '/' + id : ''}`,

    orderFilesListUrl: () => `${baseUrl}${orderFiles}/file/get`,

    orderFileUpload: () => `${baseUrl}${orders}/upload-orders-file`,

    getImgUrl: () => `${baseUrl}/files/download/`,
    showOriginalImgUrl: () => `${baseUrl}/files/show-image/`,

    updatePlanningUrl: () => `${baseUrl}${production}/planning/update`,
    updateSowingUrl: () => `${baseUrl}${production}/sowing/update`,
    updateGerminationUrl: () => `${baseUrl}${production}/germination/update`,
    updateTidalFloorUrl: () => `${baseUrl}${production}/tidal-floor/update`,
    updateStageUrl: () => `${baseUrl}${production}/update-stage-production-line`,
    updateCompartmentTransplantUrl: () => `${baseUrl}${production}/compartment_transplant/update`,
    savePollenHarvestRecollectionUrl: () => `${baseUrl}${production}/pollen-harvest/recollections/save`,
    deletePollenHarvestRecollectionUrl: () => `${baseUrl}${production}/pollen-harvest/recollections/delete`,
    deletePollenHarvestTubeUrl: () => `${baseUrl}${production}/pollen-harvest/tubes/delete`,
    // updatePollenHarvestTubeUrl: () => `${baseUrl}${production}/pollen-harvest/tubes/update`,
    savePollenHarvestTubesUrl: () => `${baseUrl}${production}/pollen-harvest/tubes/save`,
    getTubesByRecollectionIdUrl: id => `${baseUrl}${production}/pollen-harvest/tubes${id ? '/' + id : ''}`,
    createSetUrl: () => `${baseUrl}${production}/emasculation-pollination/sets/create`,
    updateEmasculationStatusUrl: () => `${baseUrl}${production}/emasculation-pollination/emasculation/status-change`,
    updatePollinationStatusUrl: () => `${baseUrl}${production}/emasculation-pollination/pollination/status-change`,
    addPollinationCommentUrl: () => `${baseUrl}${production}/emasculation-pollination/add-comment`,
    getRingColorsUrl: () => `${baseUrl}${production}/color/sequence/list/select`,
    savePollinationUrl: () => `${baseUrl}${production}/emasculation-pollination/pollination/add`,
    saveEmasculationUrl: () => `${baseUrl}${production}/emasculation-pollination/emasculation/add`,
    updateEmasculationUrl: () => `${baseUrl}${production}/emasculation-pollination/emasculation-update`,
    deleteEmasculationUrl: () => `${baseUrl}${production}/emasculation-pollination/emasculation/delete`,
    deletePollinationUrl: () => `${baseUrl}${production}/emasculation-pollination/pollination/delete`,
    getPollinationTubesUrl: () => `${baseUrl}${production}/emasculation-pollination/pollination-tubes`,
    getPollenHarvestTubesUrl: () => `${baseUrl}${production}/pollen-harvest/tubes`,
    saveEmasculationPollinationTubeUrl: () => `${baseUrl}${production}/emasculation-pollination/save-pollination-record`,
    getShowChangeColorOptionUrl: () => `${baseUrl}${production}/emasculation-pollination/show-change-color-option`,
    getShowAddColorOptionEmasculationUrl: () => `${baseUrl}${production}/emasculation-pollination/emasculation-show-add-color`,
    getShowAddColorOptionPollinationUrl: () => `${baseUrl}${production}/emasculation-pollination/pollination-show-add-color`,
    resetCalendarUrl: () => `${baseUrl}${production}/emasculation-pollination/reset-calendar`,
    readyCalendarUrl: () => `${baseUrl}${production}/emasculation-pollination/change-status`,
    deleteSetUrl: () => `${baseUrl}${production}/emasculation-pollination/delete-set`,
    deletePollinationRecordUrl: () => `${baseUrl}${production}/emasculation-pollination/delete-pollination-record`,
    getEmasculationPollinationCommentsUrl: () => `${baseUrl}${production}/emasculation-pollination/comments`,
    deleteEmasculationPollinationCommentsUrl: () => `${baseUrl}${production}/emasculation-pollination/delete-comment`,
    saveSampleUrl: () => `${baseUrl}${production}/harvest/sampling/save`,
    completeHarvestPollinationUrl: () => `${baseUrl}${production}/harvest/pollination/complete`,
    getSamplesByPollinationIdUrl: id => `${baseUrl}${production}/harvest/sampling${id ? '/' + id : ''}`,
    getHarvestByProductionLineIdUrl: id => `${baseUrl}${production}/harvest-tomato${id ? '/' + id : ''}`,
    getOriginalSampleImgUrl: () => `${baseUrl}/files/show-image-sample/`,
    deleteSampleUrl: id => `${baseUrl}${production}/harvest/sampling/delete${id ? '/' + id : ''}`,
    deleteHarvestUrl: id => `${baseUrl}${production}/harvest-tomato/delete${id ? '/' + id : ''}`,
    saveTomatoHarvestUrl: () => `${baseUrl}${production}/harvest-tomato/save`,
    saveHarvestDateUrl: () => `${baseUrl}${production}/harvest/save-harvest-date`,
    saveExtractionUrl: () => `${baseUrl}${production}/extractions/save`,
    getBaseImageUrl: id => `${baseUrl}/files/get-image-base64/${id}`
};