import Vue from 'vue';
import axios from 'axios';
import VueAxios from 'vue-axios';
import vue from '../main';

import { loginUrl, oauthTokenUrl } from '@routes';

Vue.use(VueAxios, axios);

// Axios interceptors
let refreshingInProgress = false;
let requestQueue = [];

function processQueue(error) {
    requestQueue.forEach(promise => {
        if (error) {
            promise.reject(error);
        } else {
            promise.resolve();
        }
    });

    requestQueue = [];
}

//TODO Las siguientes funciones de axios se usan para validar y/o enviar el token en la solicitud

Vue.axios.interceptors.request.use(config => {
    if (config.url !== loginUrl()) {
        config.headers.Authorization = `Bearer ${localStorage.getItem('token')}`;
    }

    return config;
}, error => Promise.reject(error));

Vue.axios.interceptors.response.use(response => response, error => {
    if (error.response.status === 401 && error.response.config.url !== loginUrl()) {
        localStorage.removeItem('token');

        if (vue.$route.name) {
            vue.$router.replace({ name: 'Login', params: vue.$route.params });
        }
    } else if (error.response.status === 400 && error.response.config.url === loginUrl()) {
        return Promise.resolve(error.response);
    } else {
        return Promise.reject(error);
    }
});

/* function refreshToken() {
    return new Promise((resolve, reject) => {

        let formData = new FormData();
        formData.set('grant_type', 'refresh_token');
        formData.set('refresh_token', localStorage.getItem('refreshToken'));

        axios.post(oauthTokenUrl(), formData)
            .then(response => {
                resolve(response);
            }).catch(error => {
                reject(error);
            });
    });
} */