import '@babel/polyfill';
import 'mutationobserver-shim';
import Vue from 'vue';
import App from './App.vue';
import router from './router';
import Permission from '@/js/permission';

import './plugins/bootstrap-vue';
import './plugins/font-awesome';
import './plugins/vee-validate';
import './plugins/vue-axios';
import './plugins/vue-sweetalert';
import './plugins/vue-select';
import './plugins/cooper';

import './scss/style.scss';

Vue.config.productionTip = false;
Vue.prototype.$permission = Permission;

let vue = new Vue({
    router,
    render: h => h(App)
}).$mount('#app');

export default vue;
