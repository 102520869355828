import Vue from 'vue';
import VueRouter from 'vue-router';

import { userTokenUrl } from '@routes';
import Permission from '@/js/permission';

Vue.use(VueRouter);

const routes = [
    {
        path: '/login',
        name: 'Login',
        component: () => import('../views/Login'),
        beforeEnter: (to, from, next) => {
            const token = localStorage.getItem('token');

            if (token) {
                localStorage.removeItem('token');
            }

            next();
        }
    },
    {
        path: '/',
        component: () => import('../views/Base'),
        beforeEnter: (to, from, next) => {
            const token = localStorage.getItem('token');

            if (token) {
                next();
            } else {
                next({ name: 'Login', replace: true });
            }
        },
        children: [
            {
                path: 'dashboard',
                name: 'DashboardIndex',
                component: () => import('../views/Dashboard/Index'),
                meta: {
                    module: 'dashboard'
                }
            },
            {
                path: 'production_lines',
                name: 'ProductionLineIndex',
                component: () => import('../views/ProductionLines/Index'),
                meta: {
                    module: 'production_lines',
                    rule: 'ROLE_PRLI_L'
                }
            },
            {
                path: 'production_lines/new',
                name: 'ProductionLineNew',
                component: () => import('../views/ProductionLines/Form'),
                meta: {
                    module: 'production_lines',
                    rule: 'ROLE_PRLI_C'
                }
            },
            {
                path: 'production_lines/:id/edit',
                name: 'ProductionLineEdit',
                component: () => import('../views/ProductionLines/Form'),
                meta: {
                    module: 'production_lines',
                    rule: 'ROLE_PRLI_C'
                }
            },
            {
                path: 'production_lines/:id/show',
                name: 'ProductionLineShow',
                component: () => import('../views/ProductionLines/Show'),
                meta: {
                    module: 'production_lines',
                    rule: 'ROLE_PRLI_L'
                }
            },
            {
                path: 'bulk_load',
                name: 'BulkLoadIndex',
                component: () => import('../views/OrderFiles/Index'),
                meta: {
                    module: 'bulk_load',
                    rule: 'ROLE_BULO_L'
                }
            },
            {
                path: 'varieties',
                name: 'VarietiesIndex',
                component: () => import('../views/Varieties/Index'),
                meta: {
                    module: 'varieties',
                    rule: 'ROLE_VARI_L'
                }
            },
            {
                path: 'parents',
                name: 'ParentsIndex',
                component: () => import('../views/Parents/Index'),
                meta: {
                    module: 'parents',
                    rule: 'ROLE_PARE_L'
                }
            },
            {
                path: 'crops',
                name: 'CropsIndex',
                component: () => import('../views/Crops/Index'),
                meta: {
                    module: 'crops',
                    rule: 'ROLE_CROP_L'
                }
            },
            {
                path: 'compartments',
                name: 'CompartmentIndex',
                component: () => import('../views/Compartments/Index'),
                meta: {
                    module: 'compartment',
                    rule: 'ROLE_COMP_L'
                }
            },
            {
                path: 'compartments/:id',
                name: 'CompartmentsShow',
                component: () => import('../views/Compartments/Show'),
                meta: {
                    module: 'compartment',
                    rule: 'ROLE_COMP_L'
                }
            },
            {
                path: 'users',
                name: 'UsersIndex',
                component: () => import('../views/Users/Index'),
                meta: {
                    module: 'users',
                    rule: 'ROLE_USER_L'
                }
            }
        ]
    }
];

const router = new VueRouter({
    mode: 'history',
    routes
});

router.beforeEach((to, from, next) => {
    if (to.name === 'Login') {
        next();
    } else {
        if (Permission.check(to.meta.rule) || !to.meta.rule) {
            next();
        } else {
            next({ name: 'Login', replace: true });
        }
    }
});

export default router;
